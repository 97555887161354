import { axios } from 'shared/axios';

export type TAuthToken = {
    authToken: string;
};

export type TAuth = {
    accessToken: string;
    refreshToken: string;
    serviceToken: string;
    expiresIn: number;
};

export const login = async (values: TAuthToken): Promise<TAuth> => {
    const { data } = await axios.post(`/auth/login/google`, { ...values });
    return data;
};

export const getNewAccessToken = async (): Promise<TAuth> => {
    const { data } = await axios.post('/access/refresh');
    return data;
};
